//document.addEventListener("turbo:load", () => { });
document.addEventListener("DOMContentLoaded", () => {
  if (!document.querySelector("#static_pages_layout")) return;

  let toggler = document.querySelector("#toggler");
  let closeBtn = document.querySelector("#close-hamburger-menu-btn");
  let hamburgerMenu = document.querySelector("#hamburger-menu");

  toggler.addEventListener("click", () => {
    hamburgerMenu.classList.remove("hidden");
  });

  closeBtn.addEventListener("click", () => {
    hamburgerMenu.classList.add("hidden");
  });
});
